import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Cart, Course14, Course15,userprofile, Course4, logo, Messages, Notification, User1, User11, User2, User3, Wish ,ChahalLogo} from "../../../imagepath";
import HeadModule from "../module";
import axios from "../../../../api/axios";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import { LogOut,  Star, User  } from "react-feather";
const COURSECATEGORY = "/api/admin/course/menu";
import axiosInstance from "../../../../api/axios1";
const DemoHeader = ({activeMenu}) => {
    const tokenStudent = localStorage.getItem("tokenStudent");
    const nameuser = localStorage.getItem("nameuser");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  const [mobileSubMenu5, setMobileSubMenu5] = useState(false);
  const [mobileSubMenu6, setMobileSubMenu6] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const [showMorecourses, setShowMoreCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const openMobileMenu = (event) => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = (event) => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu22 = (e) => {
    e.preventDefault();
    setMobileSubMenu22(!mobileSubMenu22);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  const openMobileSubMenu32 = (e) => {
    e.preventDefault();
    setMobileSubMenu32(!mobileSubMenu32);
  };
  const openMobileSubMenu4 = (e) => {
    e.preventDefault();
    setMobileSubMenu4(!mobileSubMenu4);
  };
  const openMobileSubMenu42 = (e) => {
    e.preventDefault();
    setMobileSubMenu42(!mobileSubMenu42);
  };
  const openMobileSubMenu43 = (e) => {
    e.preventDefault();
    setMobileSubMenu43(!mobileSubMenu43);
  };
  const openMobileSubMenu5 = (e) => {
    e.preventDefault();
    setMobileSubMenu5(!mobileSubMenu5);
  };
  const courseslist = async () => {
    try {
      const response = await axios.get(COURSECATEGORY);
      setCourses(response?.data?.message);
    } catch (error) {}
  };
  useEffect(() => {
    courseslist();
  }, []);
  
  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);
  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));
  const LOGOUT = "/api/student/logout";
  const logOut = async () => {
    try {
      const lastVisiteStudentRoute = localStorage.getItem(
        "lastVisiteStudentRoute"
      );
      localStorage.removeItem("lastVisiteStudentRoute");
      const responce = await axiosInstance.post(LOGOUT, {}, config);
      if (responce) {
        openNotificationWithIcon1("success");

        setTimeout(() => {
          navigate("/login");
        }, 1000);
        const keysToRemove = Object.keys(localStorage).filter((key) =>
          key.startsWith("notificationClosed_")
        );
        keysToRemove.forEach((key) => localStorage.removeItem(key));
        // toast.success("Logout Successful");
        // console.log(tokenStudent,"token")
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification");
      }
    } catch (error) {
      console.error(error);
      if (error.response.status == 401) {
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification");

        // console.log(tokenStudent,"token")

        navigate("/login");
        const keysToRemove = Object.keys(localStorage).filter((key) =>
          key.startsWith("notificationClosed_")
        );
        keysToRemove.forEach((key) => localStorage.removeItem(key));
      } else {
        localStorage.removeItem("tokenStudent");
        localStorage.removeItem("showNotification");
        const keysToRemove = Object.keys(localStorage).filter((key) =>
          key.startsWith("notificationClosed_")
        );
        keysToRemove.forEach((key) => localStorage.removeItem(key));

        // console.log(tokenStudent,"token")

        navigate("/login");
      }
    }
  };
  return (
    <>
      <header className="header header-page">
        <div className="header-fixed">
          <nav 
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
          >
            <div className="container" style={{border:'none',minWidth:"100%"}}>
              <div className="navbar-header">
              <Link
                  id="mobile_btn"
                  to="#"
                  onClick={openMobileMenu}
                >
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  <img
                    src={ChahalLogo}
                    style={{marginTop:"-20px"}}
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <div className="d-block d-sm-block d-md-none">
                <Link  className="nav-link header-sign mt-1"  to="/login">
                    <p style={{background:"#f66962"}} className="px-3 py-2  text-white">Login</p>
                  </Link>
                </div>
              </div>
              <div className="main-menu-wrapper">
              <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                  </Link>
                  
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="#"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                 
                </div>
                <ul className="main-nav">
                <li className="has-submenu">
                    <Link to="/">Home</Link>
                  </li>
                  {/* <li className="has-submenu">
                    <Link to="/course-grids">courses</Link>
                  </li> */}
                  {courses?.map((item,index)=>{
                  return(
                    index < 4 &&  
                   
                    <li className="has-submenu">
                  <Link
                    to={`/student/courses-list/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}
                  </Link>
                
                </li>

                
                
                  
                  )
                })}
           
                <li className="  d-block d-sm-block d-md-block d-lg-none text-white" onClick={(()=>setShowMoreCourses(!showMorecourses))} ><a >More Courses <i className="fas fa-chevron-down"></i></a>
                <ul className={`submenu ${showMorecourses ? 'd-block' : 'd-none'}`} >
                {courses?.map((item,index)=>{
                  return(
                    index >= 4 &&  
                   
                    <li className="has-submenu">
                  <Link
                    to={`/student/courses-list/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}
                  </Link>
                
                </li>

                
                
                  
                  )
                })}
                  
                </ul></li>
              
                
                <li className="has-submenu d-lg-block d-md-none d-sm-none d-none "  ><a >More Courses <i className="fas fa-chevron-down"></i></a>
                <ul className={`submenu `} >
                {courses?.map((item,index)=>{
                  return(
                    index >= 4 &&  
                   
                    <li className="has-submenu">
                  <Link
                    to={`/student/courses-list/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}
                  </Link>
                
                </li>

                
                
                  
                  )
                })}
                  
                </ul></li>
                <li className="nav-item d-block d-sm-block d-md-block d-lg-none">
                  <Link className="nav-link header-sign" to="/login">
                    Login
                  </Link>
                </li>
                </ul>
              </div>

              <ul className="nav header-navbar-rht">
              {tokenStudent ? 
              (
                <div className="d-block d-sm-block d-md-none"> 
                {tokenStudent ? (
                  <li className="nav-item user-nav" style={{marginTop:"-20px"}}>
                    <Link
                      to="#"
                      className={
                        showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                      }
                      data-bs-toggle="dropdown"
                      // onClick={profileClick}
                    >
                      <span className="user-img">
                        <img src={userprofile} alt="" />
                        <span className="status online"></span>
                      </span>
                    </Link>
                    <div
                      ref={profile}
                      className={
                        showProfile
                          ? "users dropdown-menu dropdown-menu-right show modalPosition"
                          : "users dropdown-menu dropdown-menu-right"
                      }
                      data-popper-placement="bottom-end"
                    >
                      <div className="user-header">
                        <div className="avatar avatar-sm">
                          <img
                            src={userprofile}
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="user-text">
                          <h6 className="single-line">{nameuser}</h6>
                          <p className="text-muted text mb-0">Student</p>
                        </div>
                      </div>
                      <Link
                        className="dropdown-item text"
                        to="/student/setting-edit-profile"
                      >
                        <User
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon"
                        />{" "}
                        Edit Profile
                      </Link>
                      <Link
                        className="dropdown-item text"
                        to="/student/setting-student-security"
                      >
                        <Star
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon"
                        />{" "}
                        Security
                      </Link>
                      <a
                        className="dropdown-item text "
                        style={{ cursor: "pointer" }}
                        onClick={logOut}
                      >
                        <LogOut
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon "
                        />{" "}
                        Logout
                      </a>
                    </div>
                  </li>
                ) : null}
                </div>
              )
              : (
                <li className="nav-item">
                  <Link className="nav-link header-sign" to="/login">
                    Login
                  </Link>
                </li>
              )}</ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default DemoHeader;
