import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { useFormik } from "formik";
import AuthContext from "../../../context/AuthProvider";
import * as Yup from "yup";
import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../../instructor/adminHeader"; 
import {   notification, Space  } from "antd";
import "./index.css"
const UPDATEZOOM = "https://newzoomservice.chahalacademy.com/api/zoom/edit-account";
const EDITZOOM = "https://newzoomservice.chahalacademy.com/api/zoom/show";

export default function AdminEditZoom() {
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { api_key:"lmsv2" }
  };
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [submit, setSubmit] = useState(false)

  const { id } = useParams();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  console.log(decryptedData,"dec")
  const fetchZoomEdit = async () => {
    try{
      setLoading(true);
      const response = await axios.get(
        `${EDITZOOM}/${decryptedData}`,
        config
      );
      setData(response.data?.message);
      setLoading(false);
    }
    catch(error){
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
    }
  
  };
  useEffect(() => {
    fetchZoomEdit();
  }, []);
  const initalValue = {
    clientid: data?.zoom_meeting_sdks[0]?.client_id?data?.zoom_meeting_sdks[0]?.client_id:"",
    clentsecret: data?.zoom_meeting_sdks[0]?.client_secret?data?.zoom_meeting_sdks[0]?.client_secret:"",
    redirecturl: data?.zoom_meeting_sdks[0]?.redirect_url?data?.zoom_meeting_sdks[0]?.redirect_url:"",
    email: data?.email?data?.email:"",
    serverid:data?.zoom_meeting_auth_servers[0]?.client_id?data?.zoom_meeting_auth_servers[0]?.client_id:"",
    serversecret: data?.zoom_meeting_auth_servers[0]?.client_secret?data?.zoom_meeting_auth_servers[0]?.client_secret:"",
    status:data?.status,
    app_key:data?.app_key,
    server_client_account:data?.zoom_meeting_auth_servers[0]?.client_account
  };
  console.log(initalValue)
  const studentSchema = Yup.object({
    clientid: Yup.string().min(1).required("Please enter client id"),
    clentsecret: Yup.string().min(1).required("Please enter your clent secret"),
    redirecturl: Yup.string().min(1).required("Please enter your redirect url"),
    email: Yup.string().email().required("Please enter your email"),
     serversecret:Yup.string().required("Please enter your Server Client Secret"),
     serverid:Yup.string().required("Please enter your Server Client Id"),

     server_client_account:Yup.string().required("Please enter your Server Client Account")

  });
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  
  const[toast,setToast]=useState(false)
  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    notification[type]
    (
      
      {
      message: 
      (
        <div style={{color:"#52c41a"}}>
         <strong>Account Updated</strong> 
        </div>
      ),
      duration:1
    
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Created
  
      // </div>)
      
    });
  
    
  
       
     
     
    };
    const openNotificationWithIcon2 = (type) => {
      // console.log("toast after click")
      notification[type]
      (
        
        {
        message: 
        (
          <div style={{color:"red"}}>
           <strong>Internal Server Error </strong> 
          </div>
        ),
      
        // description:
        // (<div style={{color:"green"}}>
        // Your Batch has Sucessfully Deleted
    
        // </div>)
        duration:1
      });
      };
      useEffect(() => {
        const lastVisitedRoute=localStorage.getItem("lastVisitedRoute")
        if(lastVisitedRoute){
          localStorage.removeItem("lastVisitedRoute")
        }
        else{
          localStorage.setItem("lastVisitedRoute", window.location.pathname);
    
        }
      }, []);
  const { errors, values, touched, handleChange, handleSubmit, getFieldProps } =
    useFormik({
      initialValues: initalValue,
      onSubmit: async (value) => {
        try {
          const response = await axios.put(
            `${UPDATEZOOM}/${decryptedData}`,
            {
              meeting_client_id: value.clientid,
              meeting_client_secret: value?.clentsecret,
              meeting_redirect_url: value?.redirecturl,
              email: value?.email,
              server_client_id: value?.serverid,
              server_client_secret: value?.serversecret,
              status:value?.status,
              app_key:"lmsv2",
              server_client_account:value?.server_client_account
            },
            config
          );
          if (response) {
            openNotificationWithIcon("success")
            setTimeout(() => {
              // navigate("/admin/test-series");
              navigate(-1);
            }, 1000);
            // toast.info("Account Updated");
          }
        } catch (error) {
          console.log(error);
          openNotificationWithIcon2("error")
        }
      },
      enableReinitialize: true,
      validationSchema: studentSchema,
    });
    // const windowTop = (arg1, arg2) => {
    //   window.scrollTo(arg1, arg2);
    // };
    
    //   useEffect(() => {
    //     windowTop(0, 0);
    //   }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader/>
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container"
        style={{minWidth:"100%",marginTop:"-15px"}}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Manage Account</h3>
                    {/* <p>Edit Test Series information.</p> */}
                    <nav aria-label="breadcrumb" style={{marginTop:'0.5em'}} >
                      <ol className="breadcrumb" style={{marginBottom:0.5}}>
                      <li
                          className="breadcrumb-item "
                          aria-current="page"
                          role="button"
                          onClick={() => navigate("/admin/live-classes")}
                        >
                          Live Classes
                        </li>
                        <li className="breadcrumb-item" style={{fontWeight:'normal'}} role="button" onClick={()=>navigate('/admin/manage-details')} aria-current="page">
                        Manage Accounts
                        </li>
                        <li className="breadcrumb-item active" style={{marginLeft:'-1em'}} role="button" aria-current="page">
                        Edit Manage Account
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                      <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Email</label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              step="0.01"
                              value={values?.email}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Email"
                            />
                            {errors.email && touched.email ? (
                              <span style={{ color: "red" }}>
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Client Id
                            </label>
                            <input
                              type="text"
                              name="clientid"
                              id="clientid"
                              value={values.clientid}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Client Id"
                            />
                            {errors.clientid && touched.clientid ? (
                              <span style={{ color: "red" }}>
                                {errors.clientid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Client SecretId
                            </label>
                            <input
                              type="text"
                              name="clentsecret"
                              id="clentsecret"
                              value={values.clentsecret}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Client Secret"
                            />
                            {errors.clentsecret && touched.clentsecret ? (
                              <span style={{ color: "red" }}>
                                {errors.clentsecret}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Redirect Url
                            </label>
                            <input
                              type="text"
                              name="redirecturl"
                              id="redirecturl"
                              value={values.redirecturl}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Redirect Url"
                            />
                            {errors.redirecturl && touched.redirecturl ? (
                              <span style={{ color: "red" }}>
                                {errors.redirecturl}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Server ClinetId
                            </label>
                            <input
                              type="text"
                              name="serverid"
                              id="serverid"
                              value={values.serverid}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Server ClinetId"
                            />
                            {errors.serverid && touched.serverid ? (
                              <span style={{ color: "red" }}>
                                {errors.serverid}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Server SecretId
                            </label>
                            <input
                              type="text"
                              name="serversecret"
                              id="serversecret"
                              value={values.serversecret}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Server SecretId"
                            />
                            {errors.serversecret && touched.serversecret ? (
                              <span style={{ color: "red" }}>
                                {errors.serversecret}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Server Client Account</label>
                            <input
                              type="text"
                              name="server_client_account"
                              id="server_client_account"
                              // step="0.01"
                              value={values?.server_client_account}
                              className="form-control"
                              onChange={handleChange}
                              placeholder="Enter Server Client Account"
                            />
                            {errors?.server_client_account && touched?.server_client_account ? (
                              <span style={{ color: "red" }}>
                                {errors?.server_client_account}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className="form-group"
                            // style={{ marginTop: "10px" }}
                          >
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Status</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  checked={getFieldProps("status").value == false}
                                  value="false"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  checked={getFieldProps("status").value == true}
                                  value="true"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.status && touched.status ? (
                                <span style={{ color: "red" }}>
                                  {errors.status}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      
                        <div className="update-profile"
                        id="submit-button"
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                           
                              <button type="submit" className="btn btn-primary"
                                disabled={submit}

                              >
                                Submit
                              </button>

                          

                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"

                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
