import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import AdminHeader from "../../instructor/adminHeader";
import Footer from "../../footer";
import { Share, User11 } from "../../imagepath";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentSideBar from "../sidebar";
import CryptoJS from "crypto-js";
import Select from "react-select";
import * as Yup from "yup";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import TextEditor from "../../pages/course/addCourse/textEditor";
import AuthContext from "../../../context/AuthProvider";
import axios from "../../../api/axios";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { Toast, Col, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
const FEEDEDIT = "/api/feeds/show";
const FEEDUPDATE = "/api/feeds/update";
const COURSECATEGORY="/api/course-category/show-active"
const FEDDMASTER="/api/feed-master/show-active"
const UPLOADIMAGE="api/uploads";

const COURSETIME="/api/course-type/show-active"

import { notification, Space } from "antd";
import { event } from "jquery";

export default function EditFedds() {
  const [showToast, setShowToast] = useState(false);
  const [option, setOption] = useState(null);
  const [relation, setRelation] = useState(null);
  const [errorI, setErrorI] = useState("");
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();

  const [radio, setRadio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const [submit, setSubmit] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCoursetime, setSelectedCoursetime] = useState([]);
  const [selectedCoursevalue, setSelectedCoursevalue] = useState("");
  const [selectedCoursetimevalue, setSelectedCoursetimevalue] = useState("");


  var bytes = CryptoJS.AES.decrypt(id, "my-secret-key@admin8623009959");
  var decryptedData = JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchCourseCategory = async () => {
    try {
        const res = await axios.get(FEDDMASTER, config)
// console.log(res,"res")
        setSelectedCourse(res?.data?.message)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }

    }
}
// console.log(selectedCourse,"selectedcourse ")

useEffect(() => {
  fetchCourseCategory();
}, []);
  const fetcheditfeeds = async () => {
    try {
      setLoading(true);
      const responce = await axios.get(
        `${FEEDEDIT}/${decryptedData}`,
        config
      );
      // console.log(responce?.data);
    //   setOption(responce?.data?.batches);
      setData(responce?.data?.message);
    //   setSelectedCoursetimevalue(responce?.data?.courseType)


      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion('error')
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("Internal Server Error","error")
      }
    }
  };


  useEffect(() => {
    fetcheditfeeds();
  }, []);
  // console.log(relation,"relation")
  const initalValue = {
    name: data?.name?data?.name:"",
    status: data?.status?data?.status:"",
    description: data?.content?data?.content:"",
    // file: data?.file,
    fk_feed_master_id:data?.fk_feed_master_id,

  };
  //  console.log(initalValue,"krishna")
  const courseSchema = Yup.object({
    name: Yup.string().min(2).required("Please enter your name"),
    status: Yup.string().required("Please select your status"),
    // description: Yup.string().required("Please enter your description"),
    fk_feed_master_id:Yup.string().required("Please Selecte your course Time"),

    // batch: Yup.array()
    // .min(1, "Pick at least one items")
    // .of(
    //   Yup.object().shape({
    //     label: Yup.string().required(),
    //     value: Yup.string().required(),
    //   })
    // ),
  });
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Course Updated</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon2 = (text, type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const UploadImage = async (e) => {
    try {
        const formData=new FormData()
        // console.log(e?.target?.files[0],"file in formdata")

        // if (file) {
        //   } else if (file !== null) {
        //     formData.append("file", file);
        //   } else {
        //     formData.append("file", file);
        //   }
       
        // formData.append("file",file?.name)

        formData.append("type","image");

        formData.append("file", e?.target?.files[0]);
        const res = await axios.post(UPLOADIMAGE, formData,config)
        // console.log(res,"res")
                setFilePre(res?.data?.message)


       
    } 
    catch (error) {
        console.log(error?.response?.data?.message);
        setImageError()
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }
        openNotificationWithIcon("error",error?.response?.data?.message,"red")

    }
}
// console.log(file,"file")
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmit(true);
      
      try {
        // console.log(value);
        setErrorI("");
        const formData = new FormData();
    
        if (file !== null) {
          formData.append("image", filePre==undefined?data?.file:filePre);
        }
        formData.append("name", values?.name);
        formData.append("status", values.status);
        formData.append("fk_feed_master_id",values?.fk_feed_master_id)
        formData.append("content",values?.description)

        // console.log(formData,"formdata")
        const response = await axios.put(
          `${FEEDUPDATE}/${decryptedData}`,
          {name:values?.name,
            status:values?.status,
            fk_feed_master_id:values?.fk_feed_master_id,
            content:values?.description,
            file:filePre==undefined?data?.file:filePre

          },
          config
        );
        // toast.info("Course Updated", {
        //   position: "top-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: 0,
        //   theme: "colored",
        //   transition:"flip"
        //   });

        if (response) {
          openNotificationWithIcon("success");
          setTimeout(() => {
            navigate("/admin/feeds");
          }, 600);
        }
      } catch (error) {
        console.log(error?.response?.data?.error);
        setSubmit(false);

        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon2(error?.response?.data?.error, "error");
        }
        if (error.response.data.errors.image) {
          toast.warning(error.response.data.errors.image.toString());
          setErrorI(error.response.data.errors.image.toString());
        }
        else{
          openNotificationWithIcon2(error?.response?.data?.error, "error");

        }
      }
    },
    validationSchema: courseSchema,
  });
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const handleRadio = (e) => {
    if (e === "option2") {
      setFieldValue("amount", 0);
    }
    setRadio(e);
  };

  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  //   useEffect(() => {
  //     windowTop(0, 0);
  //   }, []);
  return (
    <div className="main-wrapper">
      {/* <StudentHeader activeMenu={"Profile"} /> */}
      {/* Student Dashboard */}
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ maxWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"feeds"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Feeds</h3>{" "}
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate("/admin/feeds")}>
                            Feeds
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Edit Feeds
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/* <div className="course-group mb-0 d-flex">
                    <div className="course-group-img d-flex align-items-center">
                      <Link to="student-profile">
                        <img src={User11} alt="" className="img-fluid" />
                      </Link>
                      <div className="course-name">
                        <h4>
                          <Link to="student-profile">Your avatar</Link>
                        </h4>
                        <p>PNG or JPG no bigger than 800px wide and tall.</p>
                      </div>
                    </div>
                    <div className="profile-share d-flex align-items-center justify-content-center">
                      <Link to="#;" className="btn btn-success">
                        Update
                      </Link>
                      <Link to="#;" className="btn btn-danger">
                        Delete
                      </Link>
                    </div>
                  </div> */}
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form>
                      {loading === true ? (
                        <div
                          className="d-flex justify-content-center"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="spinner-border text-warning"
                            style={{
                              left: "50%",
                              marginLeft: "24em",
                              marginTop: "10em",
                            }}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Course Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                id="name"
                                name="name"
                                value={values?.name}
                                onChange={handleChange}
                              />
                              {/* {errors?.name && touched?.name ? (
                                <span style={{ color: "red" }}>
                                  {errors?.name}
                                </span>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-label">Feed Type</label>
                            <select
                              className="form-control"
                              id="fk_feed_master_id"
                              name="fk_feed_master_id"
                              value={values?.fk_feed_master_id}
                              onChange={(e) => setFieldValue("fk_feed_master_id", e?.target?.value)}
                            >
                              <option value="">Select a </option>
                              {selectedCourse?.map((course) => (
                                <option key={course?.id} value={course?.id}>
                                  {course?.name}
                                </option>
                              ))}
                            </select>
                               
                            {!selectedCourse && (
                              <div className="text-danger">
                                Please select a Feeds Type.
                              </div>
                            )}
                          </div>

                          
                        </div>
                         
                          
                          

                          <div className="col-lg-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Cover image
                              </label>
                              <input
                                type="file"
                                id="image"
                                name="image"
                                // value={values.image}
                                className="form-control"
                                placeholder="Cover Image"
                                onChange={(e) => {setFile(e?.target?.files[0]);
                                  UploadImage(e);      

                                 
                                  // console.log(file,"file in onChnage")
                              }}
                              />
                              {/* {values.image && (
                                <img
                                  src={`https://lmscode.stellarflux.in/${values.image}`}
                                  alt="no image"
                                  height={50}
                                  width={90}
                                />
                              )} */}
                              {errorI ? (
                                <span style={{ color: "red" }}>{errorI}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div
                                className="add-image-box"
                                style={{ minHeight: "0", height: "200px" }}
                              >
                                {values?.file === null ? (
                                  <img
                                    src={Share}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                ) : (
                                  <img
                                    src={filePre?filePre:data?.file}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-0">
                            <label className="form-control-label">
                              Content
                            </label>
                            <div id="editor">
                              <TextEditor
                                type="description"
                                id="description"
                                name="description"
                                value={values?.description!==null?values?.description:""}
                                setFieldValue={setFieldValue}
                              />
                              {/* <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                name="description"
                                // onBlur={handleBlur}
                                value={values.description}
                                onChange={handleChange}
                                rows="3"
                              ></textarea> */}
                              {errors?.description && touched?.description ? (
                                <span style={{ color: "red" }}>
                                  {errors.description}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        
                          
                       
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios1"
                                  onChange={getFieldProps("status").onChange}
                                  value={"0" || values?.status}
                                  checked={values?.status === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios1"
                                >
                                  InActive
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inlineRadios2"
                                  checked={values?.status === "1"}
                                  onChange={getFieldProps("status").onChange}
                                  value={"1" || values?.status}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadios2"
                                >
                                  Active
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="update-profile" id="submit-button">
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={submit}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setShowToast(true);
                                  handleSubmit();
                                }}
                              >
                                Update
                              </button>
                            </div>{" "}
                            <div
                              className="btn-group mr-2"
                              role="group"
                              aria-label="First group"
                              id="custom-submit"
                            >
                              <button
                                type="button"
                                onClick={(event) => {event.preventDefault();
                                  navigate(-1)}}
                                className="btn btn-primary cancel-btn "
                              >
                                Cancel
                              </button>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>

      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
