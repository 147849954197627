import React, { useContext, useEffect, useState } from "react";
import StudentHeader from "../header";
import Footer from "../../footer";
import { User11 } from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import StudentSideBar from "../sidebar";
import { Select } from 'antd';
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../../pages/course/addCourse/textEditor";
import { useFormik } from "formik";
import axios from "../../../api/axios";
import * as Yup from "yup";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../../instructor/adminHeader";
import { async } from "q";
import { notification, Space } from "antd";
import "./index.css"

const CREATEINVOICE = "/api/invoice/create";
const ADDBATCH = "/api/course/add-batch";
const STUDENTSNAME="/api/course/student/list"
const COURSESNAME="/api/invoicelist"
const PAYMENTMETHOD="/api/payment_modes"
const CENTERLIST="/api/institute/center"
const COURSETIME="/api/course-type/show-active"
const UPLOADIMAGE="api/uploads";
const { Option } = Select;

const initalValue = {
  name: "",
  coursename:"",
  amount:"",
  cgst:"",
  sgst:"",
  date:"",
  roundof:"",
  invoiceno:"",
  cheque:"",
  ref_number:""
};
const courseSchema = Yup.object({
  amount: Yup.string().min(2).required("Enter Your Amount"),
  cgst: Yup.string().min(2).required("Enter Your CGSt"),
  sgst: Yup.string().min(2).required("Enter Your SGST"),
  date: Yup.string().min(2).required("Enter Your Invoice Date"),
  roundof: Yup.string().min(1).required("Enter Your Round Off"),
  cheque: Yup.string().min(2).required("Enter Your Cheque/NEFT NO"),
  invoiceno: Yup.string().min(2).required("Enter Your Invoice No"),
  ref_number: Yup.string().min(2).required("Enter Your Reference No"),

});

export default function AddAdminInvouceCreate() {
  const [country, setCountry] = useState(null);
  const [file, setFile] = useState(null);
  const [filePre, setFilePre] = useState();
  const [radio, setRadio] = useState(null);
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [api, contextHolder] = notification.useNotification();
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedpaymentId, setSelectedPaymentId] = useState(null);
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);

  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type, text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconerror = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>course is already exists</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    initialValues: initalValue,
    
    onSubmit: async (values, { setFieldError }) => {
        // console.log("krishna before try");

      setSubmit(true);
      try {

        // console.log("krishna");
        const formData = new FormData();
        formData.append("user_id", selectedStudentId);
        formData.append("course_id", selectedCourseId);
        formData.append("payment_mode_id",selectedpaymentId)
        formData.append("institute_center_id",selectedCenterId)
        formData.append("amount", values.amount);
        formData.append("cgst", values.cgst);
        formData.append("sgst",values?.sgst)
        formData.append("inv_date",values?.date)
        formData.append("roundoff",values?.roundof)
        formData.append("cheque_neft",values?.cheque)
        formData.append("inv_number",values?.invoiceno)
        formData.append("ref_number",values?.ref_number)

        const response = await axios.post(CREATEINVOICE, formData, config);
        // console.log(response);
        if (response) {
          openNotificationWithIcon("success", "Invoice Created", "#52c41a");
          setTimeout(() => {
            navigate("/admin/invoice");
          }, 800);
          
          // toast.success("Course Created");
        }
      } catch (error) {
        setSubmit(false);
        console.log(error);
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red");
        } else {
          openNotificationWithIcon("error", "Internal Server Error", "red");
        }
        // if (error.response.data.errors.image) {
        //   setFieldError("image", error.response.data.errors.image.toString());
        // }
     
      }
    },
     validationSchema: courseSchema,
  });
  
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      border: "1px solid #e9ecef",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };
  const fetchCourseCategory = async () => {
    try {
        const res = await axios.get(COURSESNAME, config)
  // console.log(res,"res")
        setSelectedCourse(res?.data)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          openNotificationWithIconSesion("error")
            localStorage.removeItem('token')
            navigate('/admin/login')
        }
        else{
          openNotificationWithIcon2("error","Internal Server Error")
        }

    }
}
const handleCourseChange = (value) => {
    //  console.log(value,"value course")
    setSelectedCourseId(value);
  };
  const handleStudentChange = (value) => {
    // console.log(value,"value student")
    setSelectedStudentId(value);
  };
  const handlePaymentChange = (value) => {
    // console.log(value,"value payment")
    setSelectedPaymentId(value);
  };
  const handleCenterChange = (value) => {
    // console.log(value,"value")
    setSelectedCenterId(value);
  };

  const fecthStudentsName = async () => {
    try {
        const res = await axios.get(`${STUDENTSNAME}/${selectedCourseId}`, config)
          //  console.log(res,"res")
        setSelectedStudent(res?.data)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }

    }
}
const fetchPaymentType = async () => {
    try {
        const res = await axios.get(PAYMENTMETHOD, config)
//  console.log(res,"res")
        setSelectedPayment(res?.data)
    } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
            localStorage.removeItem('token')
            navigate('/admin/login')
        }

    }
}
const InstituteList = async () => {
  try {
      const res = await axios.get(CENTERLIST, config)
const data=[]
data.push(res?.data)
// console.log(data,"data")
     setSelectedCenter(data)
  } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
          localStorage.removeItem('token')
          navigate('/admin/login')
      }

  }
}
useEffect(() => {
  fecthStudentsName()
  fetchCourseCategory()
  fetchPaymentType()
  InstituteList()
}, [selectedCourseId]);
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
//  console.log(selectedCourseId,"courseid",selectedStudentId,"student",selectedpaymentId,"payment",selectedCenterId,"centerid")
  

  return (
    <div className="main-wrapper">
      <AdminHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"invoice"} />
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-9 col-md-8 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Add Invoice</h3>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb" style={{ marginBottom: 0.5 }}>
                        <li className="breadcrumb-item" role="button">
                          <a onClick={() => navigate("/admin/invoice")}>
                            Invoice
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          role="button"
                          aria-current="page"
                        >
                          Add Invoice
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="checkout-form personal-address add-course-info ">
                    <div className="personal-info-head"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
               
    <div className="col-lg-6">
      <div className="form-group d-flex"
      style={{flexDirection:"column"}}
      >
        <label className="form-control-label">Course Name</label>
        <Select
          showSearch
          style={{ width: "100%",height:"100%" }}
          placeholder="Select an option"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
         onChange={handleCourseChange}
        >
          {selectedCourse?.map((option) => (
            <Option key={option?.id} value={option?.id}>
              {option?.name}
            </Option>
          ))}
        </Select>
      </div>
    </div>
    <div className="col-lg-6">
      <div className="form-group d-flex"
      style={{flexDirection:"column"}}
      >
        <label className="form-control-label">Student Name</label>
        <Select
          showSearch
          style={{ width: "100%",}}
          placeholder="Select an option"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleStudentChange}

        >
          {selectedStudent?.map((option) => (
            <Option key={option.user_id} value={option.user_id}>
              {option.user_detail?.name}
            </Option>
          ))}
        </Select>
        {/* {errors.name && touched.name ? (
          <span style={{ color: 'red' }}>{errors.name}</span>
        ) : null} */}
      </div>
    </div>
    <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               Invoice No
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your  Invoice No"
                              id="invoiceno"
                              name="invoiceno"
                              style={{height:"20px"}}
                              value={values?.invoiceno}
                              onChange={handleChange}
                            />
                            {errors?.invoiceno && touched?.invoiceno ? (
                              <span style={{ color: "red" }}>
                                {errors?.invoiceno}
                              </span>
                            ) : null}
                          </div>
                        </div>
    <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               Invoice Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Select Your Date"
                              id="date"
                              name="date"
                              style={{height:"20px"}}
                              value={values?.date}
                              onChange={handleChange}
                            />
                            {errors?.date && touched?.date ? (
                              <span style={{ color: "red" }}>
                                {errors?.date}
                              </span>
                            ) : null}
                          </div>
                        </div>
                         <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               Amount
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your  Amount"
                              id="amount"
                              name="amount"
                              style={{height:"20px"}}
                              value={values?.amount}
                              onChange={handleChange}
                            />
                            {errors.amount && touched.amount ? (
                              <span style={{ color: "red" }}>
                                {errors.amount}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               CGST
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your  CGST"
                              id="cgst"
                              name="cgst"
                              style={{height:"20px"}}
                              value={values.cgst}
                              onChange={handleChange}
                            />
                            {errors.cgst && touched.cgst ? (
                              <span style={{ color: "red" }}>
                                {errors.cgst}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               SGST
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your  SGST"
                              id="sgst"
                              name="sgst"
                              style={{height:"20px"}}
                              value={values.sgst}
                              onChange={handleChange}
                            />
                            {errors.sgst && touched.sgst ? (
                              <span style={{ color: "red" }}>
                                {errors.sgst}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               Round Off
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter your  Round Off"
                              id="roundof"
                              name="roundof"
                              style={{height:"20px"}}
                              value={values?.roundof}
                              onChange={handleChange}
                            />
                            {errors.roundof && touched.roundof ? (
                              <span style={{ color: "red" }}>
                                {errors.roundof}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               Cheque/NEFT No
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your  Cheque/NEFT NO"
                              id="cheque"
                              name="cheque"
                              style={{height:"20px"}}
                              value={values.cheque}
                              onChange={handleChange}
                            />
                            {errors?.cheque && touched?.cheque ? (
                              <span style={{ color: "red" }}>
                                {errors?.cheque}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6">
      <div className="form-group d-flex"
      style={{flexDirection:"column"}}
      >
        <label className="form-control-label">Payment Type</label>
        <Select
          style={{ width: "100%",}}
          className="ant-select-selector" 
          placeholder="Select an option"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handlePaymentChange}

        >
          {selectedPayment?.map((option) => (
            <Option key={option?.id} value={option?.id}>
              {option?.name}
            </Option>
          ))}
        </Select>
        {/* {errors.name && touched.name ? (
          <span style={{ color: 'red' }}>{errors.name}</span>
        ) : null} */}
      </div>
                        </div>
                        <div className="col-lg-6">
      <div className="form-group d-flex"
      style={{flexDirection:"column"}}
      >
        <label className="form-control-label">Institute Center</label>
        <Select
          style={{ width: "100%",}}
          className="ant-select-selector" 
          placeholder="Select an option"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleCenterChange}
        >
          {selectedCenter?.map((option) => (
            <Option key={option?.id} value={option?.id}>
              {option?.Name}
            </Option>
          ))}
        </Select>
        {/* {errors.name && touched.name ? (
          <span style={{ color: 'red' }}>{errors.name}</span>
        ) : null} */}
      </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                               Reference No
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your  Reference No"
                              id="ref_number"
                              name="ref_number"
                              style={{height:"20px"}}
                              value={values.ref_number}
                              onChange={handleChange}
                            />
                            {errors?.ref_number && touched?.ref_number ? (
                              <span style={{ color: "red" }}>
                                {errors?.ref_number}
                              </span>
                            ) : null}
                          </div>
                        </div>
                       
                        <div className="update-profile" id="submit-button"
                        style={{marginTop:"20px"}}
                        >
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={submit}
                            //   value={console.log("krishna in submit")}
                            >
                              Submit
                            </button>
                          </div>{" "}
                          <div
                            className="btn-group mr-2"
                            role="group"
                            aria-label="First group"
                            id="custom-submit"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="btn btn-primary cancel-btn "
                            >
                              Cancel
                            </button>
                          </div>{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <ToastContainer />
      <Footer />
    </div>
  );
}
