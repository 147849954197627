import React, { useContext, useEffect, useState } from "react";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { Link, useNavigate } from "react-router-dom";
import AdminHeader from "../../instructor/adminHeader";
import "./index.css";
import Footer from "../../footer";
import PaginationSub from "../../pages/course/addCourse/PaginationSub";
import CryptoJS from "crypto-js";
import { Button } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";
import { Tooltip } from "antd";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import axios from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import PaginationMod from "../../pages/course/addCourse/PaginationMod";
const SHOWMODULE = "/api/recorded-video/show-module";
const DELETEMODULE = "/api/recorded-video/delete-module";
const MODULESTATUS = "/api/recorded-video/status-module";
const DELETESUBMODULE = "/api/recorded-video/delete-submodule";
const QUESSTIONSTATUS = "/api/recorded-video/status-submodule";
const MODULESEARCH = "/api/recorded-video/recordedvideo-module-search";
const SUBMODULESEARCH = "/api/recorded-video/recordedvideo-submodule-search";
import "./../../instructor/adminTestSeries/index.css";
import { Modal } from "antd";
import { notification } from "antd";

const { confirm } = Modal;

export default function AddRecordedVideoModule() {
  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [show, setShow] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [last_page1, setLast_page1] = useState(null);
  const [last_page2, setLast_page2] = useState(null);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [text, settext] = useState("");
  const [text1, settext1] = useState("");

  const [id, setId] = useState("");
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [message, setmessage] = useState("No Data ");
  const [messageModule, setmessageModule] = useState("No Data ");
  const [loading, setLoading] = useState(false);
  const [listModule, setListModule] = useState(null);
  const [listSubModule, setListSubModule] = useState(null);
  const [featureTypeId, setFeatureTypeId] = useState("");
  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    try {
      const response = await axios.post(
        `${MODULESTATUS}`,
        { id: id, status: result },
        config
      );
      response;
      if (response) {
        openNotificationWithIcon1("success");
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon2("error");
    }
    // toast.success("Status Change");

    const editCheck = [...listModule];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setListModule(editCheck);
    fetchModule();
  };
  const handleSlug = (slug, id, feature_type_id) => {
    let cipherid = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let cipherids = encodeURIComponent(cipherid);
    let cipherFeatureId = CryptoJS.AES.encrypt(
      JSON.stringify(feature_type_id),
      "my-secret-key@admin8623009959"
    ).toString();
    let cipherFeatureIds = encodeURIComponent(cipherFeatureId);
    navigate(`/admin/recordedvideos/${slug}/${cipherids}/${cipherFeatureIds}`);
  };
  const { auth } = useContext(AuthContext);

  const handleClose = () => setShow(false);
  const handleCloseSub = () => setShowSub(false);
  const handleShow = async (id) => {
    setShow(true);
    setId(id);
  };
  const handleShowSub = async (id) => {
    setShowSub(true);
    setId(id);
  };

  const handleEditShow = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/recorded-videos/edit/${ciphertexts}`);
  };
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate("/admin/editvideo");
  };

  const handleChangeSub = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      QUESSTIONSTATUS,
      { id: id, status: result },
      config
    );
    // console.log(response);
    response;
    if (response) {
      openNotificationWithIconsub1("success");
    }
    // toast.success("Status Change");
    fetchModule();
    if (!response) {
      openNotificationWithIcon2("error");
    }
    const editCheck = [...listSubModule];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setListSubModule(editCheck);
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Module Deleted</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const handleSubModule = (id) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/recordedvideos/subedit/${ciphertexts}`);
  };
  const handleLinkVideo = (id) => {
    let mid = 0;
    let ciphermid = CryptoJS.AES.encrypt(
      JSON.stringify(mid),
      "my-secret-key@admin8623009959"
    ).toString();
    let cipherMid = encodeURIComponent(ciphermid);
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/recorded-videos/video-link/${cipherMid}/${ciphertexts}`);
  };
  const fetchModule = async () => {
    try {
      setLoading(true);

      const response = await axios.get(SHOWMODULE, config);
      setListModule(response.data.module.data);
      setLast_page1(response.data.module.last_page);
      setLast_page2(response.data.subModule.last_page);
      setListSubModule(response.data.subModule.data);
      setData(response.data.module.data);
      setData2(response.data.subModule.data);
      setFeatureTypeId(response.data.featuretypeid);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
  };
  const DeleteTestSeries = async () => {
    if (id) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETEMODULE}/${id}`, config);
        // console.log("resonse", resonse);
        resonse;
        // toast.warning("Module Deleted");
        setLoading(false);
        fetchModule();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };
  const handleView=(id)=>{
    navigate(`/admin/recorded-videos/videos-list/${id}`)
  }
  const showConfirmMoodule = (id) => {
    confirm({
      title: "Do you want to delete this module?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETEMODULE}/${id}`, config);
          // console.log("resonse", resonse);
          resonse;
          if (resonse) {
            openNotificationWithIcon("success");
          }
          // toast.warning("Module Deleted");
          setLoading(false);
          fetchModule();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{

          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  };

  const handleSubDelete = async () => {
    try {
      setLoading(true);
      const resonse = await axios.get(`${DELETESUBMODULE}/${id}`, config);
      resonse;
      toast.warning("SubModule Deleted");
      // console.log("resonse", resonse);
      setLoading(false);
      fetchModule();
      setShowSub(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const openNotificationWithIconsub = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>SubModule Deleted</strong>
        </div>
      ),
      duration: 1,

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIconsub1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const showConfirmSubModule = (id) => {
    confirm({
      title: "Do you want to delete this submodule?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETESUBMODULE}/${id}`, config);
          resonse;
          openNotificationWithIconsub("success");
          // toast.warning("SubModule Deleted");
          // console.log("resonse", resonse);
          setLoading(false);
          fetchModule();
          setShowSub(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  };
  useEffect(() => {
    fetchModule();
  }, []);
  const handleSubModules = () => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(featureTypeId),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/recordedvideos/subadd/${ciphertexts}`);
  };
  const Filter1 = async (e) => {
    try {
      const searchValue = e.target.value;
      settext1(searchValue);
      const res = await axios.post(MODULESEARCH, { name: searchValue }, config);
      if (res.data.module.data.length > 0) {
        setListModule(res.data.module.data);
        setCurrentPage1(res.data.module.current_page);
        setLast_page1(res.data.module.last_page);
        setmessageModule('No Data')
      } else {
        // setList(data)
      }
      if (res.data.module.data.length === 0) {
        setListModule(res.data.module.data);
        setmessageModule('No Data Found')
      }
      if (searchValue.length === 0) {
        const res = await axios.get(
          `${SHOWMODULE}?page=${currentPage1}`,
          config
        );
        setListModule(res.data.module.data);
        setCurrentPage1(1);
        setLast_page1(res.data.module.last_page);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error");

      }
    }
  };
  const handleKeyPress1 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter1();
    }
  };
  const handleKeyPress2 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter2();
    }
  };

  const Filter2 = async (e) => {
    try {
      const searchValue = e.target.value;
      settext(searchValue);
      const res = await axios.post(
        SUBMODULESEARCH,
        { name: searchValue, module: "0" },
        config
      );
      if (res.data.subModule.data.length > 0) {
        setListSubModule(res.data.subModule.data);
        setCurrentPage2(res.data.subModule.current_page);
        setLast_page2(res.data.subModule.last_page);
        setmessage('No Data')
      } else {
        // setList(data)
      }
      if (res.data.subModule.data.length === 0){
        setListSubModule(res.data.subModule.data);
        setmessage('No Data Found')
      }
      if (searchValue.length === 0) {
        const res = await axios.get(
          `${SHOWMODULE}?page=${currentPage2}`,
          config
        );
        setListSubModule(res.data.subModule.data);
        setCurrentPage2(1);
        setLast_page2(res.data.subModule.last_page);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error");

      }
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  const itemsPerPage = 10;
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div className="container" style={{ minWidth: "100%" }}>
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Videos"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div
                      className="settings-widget"
                      style={{ background: "white", height: "100px" }}
                    >
                      <div
                        className="containerr"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="sell-course-head student-meet-head p-3 border-0">
                          <div className="tak-head d-flex align-items-center">
                            <div>
                              <h3>Recorded Module & SubModule</h3>
                            </div>
                          </div>
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{ cursor: "pointer", marginTop: "0.5em" }}
                                onClick={() =>
                                  navigate("/admin/recorded-videos-list")
                                }
                              >
                                Recorded Videos
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{ cursor: "pointer", marginTop: "0.5em" }}
                                onClick={() =>
                                  navigate("/admin/recorded-videos")
                                }
                              >
                                {" "}
                                Recorded Module & SubModule
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div> */}
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3>Recorded Modules</h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{ marginBottom: "-1.6em" }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/recorded-videos-list")
                                }
                              >
                                Recorded Videos
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/recorded-videos")
                                }
                              >
                                {" "}
                                Recorded Modules
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            className="instruct-search-blk"
                            // id="table-value"
                          >
                            {/* Instructor Users */}
                            <div className="show-filter choose-search-blk">
                              <form action="#">
                                <div
                                  className="row gx-2 align-items-center "
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div className="col-md-3 col-item">
                                    <div
                                      className=" search-group"
                                      id="search-btn"
                                      // style={{ width: "220px" }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "rgb(246, 106, 98)", // Change the color to yellow
                                        }}
                                      >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                          x1="21"
                                          y1="21"
                                          x2="16.65"
                                          y2="16.65"
                                        ></line>
                                      </svg>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        // allowClear
                                        onChange={Filter1}
                                        onKeyDown={handleKeyPress1}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-lg-6 col-item">
                                    <div className="form-group select-form mb-0 d-flex justify-content-end">
                                      <Button
                                        onClick={() =>
                                          navigate("/admin/recorded-videos/add")
                                        }
                                        // style={{
                                        //   marginLeft: "5.2em",
                                        // }}
                                        className="btn btn-primary"
                                        id="custom-button"
                                      >
                                        {" "}
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
<div className="settings-referral-blk table-responsive">
{/* <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete Module</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are you sure want to delete this module ?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={DeleteTestSeries}
                                >
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal> */}
                            <table
                              className="table table-nowrap mt-4"
                              // style={{ marginTop: "15px" }}
                            >
                              <thead>
                                <tr className="text-center">
                                  <th>SR. NO.</th>
                                  <th>NAME</th>

                                  <th>STATUS</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                              
                                  <tbody  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : (
                                listModule?.map((item, i) => {
                                  const serialNumber =
                                    (currentPage1 - 1) * itemsPerPage + i + 1;
                                  return (
                                    <tbody
                                      style={{ textTransform: "capitalize" }}
                                      key={item?.id}
                                    >
                                      <tr key={item.id} className="text-center">
                                        <td
                                        // style={{border:"1px solid red"}}
                                        >
                                          {serialNumber}
                                        </td>
                                        <td>
                                          <a
                                            onClick={() =>
                                              handleSlug(
                                                item.slug,
                                                item.id,
                                                featureTypeId
                                              )
                                            }
                                            className="text-muted"
                                            role="button"
                                          >
                                            <p>{item.name}</p>
                                          </a>
                                        </td>

                                        <td>
                                          <Switch
                                            onChange={() => {
                                              handleChange(
                                                item.id,
                                                item.status
                                              );
                                            }}
                                            checked={
                                              item.status == 1 ? true : false
                                            }
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>
                                        <td>
                                          <Tooltip title="Open SubModule">
                                            <button
                                              onClick={() => {
                                                handleSlug(
                                                  item.slug,
                                                  item.id,
                                                  item.feature_type_id
                                                );
                                                // windowTop(0, 0);
                                              }}
                                              className="btn btn-primary"
                                            >
                                              {/* <i className="fa fa-caret-square-o-down" ></i> */}
                                              <i
                                                className="fa fa-arrow-circle-right"
                                                style={{ fontSize: "19px" }}
                                              ></i>
                                              {/* <i className="fa-solid fa-plus"></i> */}
                                            </button>{" "}
                                          </Tooltip>
                                          <Tooltip title="Edit">
                                            <button
                                              onClick={() =>
                                                handleEditShow(item.id)
                                              }
                                              type="button"
                                              className="btn btn-warning text-white"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                          </Tooltip>
                                         
                                          <Tooltip title="Delete">
                                            <button
                                              onClick={() =>
                                                showConfirmMoodule(item.id)
                                              }
                                              type="button"
                                              className="btn btn-danger"
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </button>{" "}
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}
                            </table>
</div>
                           
                            {listModule?.length <= 0 ? (
                              <h5 className="text-center">{messageModule}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          <div className="fixtable">
                            {listModule?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationMod
                                nPages={last_page1}
                                currentPage={currentPage1}
                                setCurrentPage={setCurrentPage1}
                                link={SHOWMODULE}
                                setData={setListModule}
                                text={text1}
                                linkSearch={MODULESEARCH}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="settings-widget"
                      style={{ marginTop: "25px" }}
                    >
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3>Recorded SubModules</h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{ marginBottom: "-1.6em" }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item "
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/recorded-videos-list")
                                }
                              >
                                Recorded Videos
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0.5em",
                                }}
                                onClick={() =>
                                  navigate("/admin/recorded-videos")
                                }
                              >
                                {" "}
                                Recorded SubModules
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="comman-space pb-0">
                          <div
                            className="instruct-search-blk"
                           
                          >
                            {/* Instructor Users */}
                           

                            <div className="show-filter choose-search-blk">
                              <form action="#">
                                <div
                                  className="row gx-2 align-items-center"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div className="col-md-3 col-item">
                                    <div
                                      className=" search-group"
                                      id="search-btn"
                                      // style={{ width: "220px" }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "rgb(246, 106, 98)", // Change the color to yellow
                                        }}
                                      >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line
                                          x1="21"
                                          y1="21"
                                          x2="16.65"
                                          y2="16.65"
                                        ></line>
                                      </svg>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        // allowClear
                                        onChange={Filter2}
                                        onKeyDown={handleKeyPress2}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-item">
                                    <div className="form-group select-form mb-0 d-flex justify-content-end">
                                      <Button
                                        onClick={() => handleSubModules()}
                                        className="btn btn-primary"
                                        id="custom-button"
                                      >
                                        {" "}
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          
                            
                            {/* Instructor List */}
                          </div>
                          <div className="settings-referral-blk table-responsive">
                          {/* <Modal show={showSub} onHide={handleCloseSub}>
                              <Modal.Header closeButton>
                                <Modal.Title>Delete SubModule</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are you sure want to delete this submodule ?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseSub}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleSubDelete}
                                >
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal> */}
                            <table
                              className="table table-nowrap mt-2"
                              // style={{ marginTop: "15px" }}
                            >
                              <thead>
                                <tr className="text-center">
                                  <th>SR. NO.</th>
                                  <th>NAME</th>

                                  <th>STATUS</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                
                                  <tbody className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                              ) : (
                                listSubModule?.map((item, i) => {
                                  const serialNumber =
                                    (currentPage2 - 1) * itemsPerPage + i + 1;
                                  return (
                                    <tbody
                                      style={{ textTransform: "capitalize" }}
                                      key={item?.id}
                                    >
                                      <tr key={item.id} className="text-center">
                                        <td>{serialNumber}</td>
                                        <td>
                                          <Link
                                            role="button"
                                            // to="/admin/courses/demo"
                                            className="text-muted"
                                          >
                                            <p>{item.name}</p>
                                          </Link>
                                        </td>

                                        <td>
                                          <Switch
                                            onChange={() => {
                                              handleChangeSub(
                                                item.id,
                                                item.status
                                              );
                                            }}
                                            checked={
                                              item.status == 1 ? true : false
                                            }
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>
                                        <td>
                                          <Tooltip title="Link Video">
                                            <button
                                              onClick={() =>
                                                handleLinkVideo(item?.id)
                                              }
                                              type="button"
                                              className="btn btn-info text-white"
                                            >
                                              <i
                                                className="fa fa-link"
                                                aria-hidden="true"
                                              ></i>
                                            </button>{" "}
                                          </Tooltip>
                                          <Tooltip title="Edit">
                                            <button
                                              onClick={() =>
                                                handleSubModule(item?.id)
                                              }
                                              type="button"
                                              className="btn btn-warning text-white"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                          </Tooltip>
                                          <Tooltip title="View videos">
                                              <a
                                                onClick={() =>
                                                  handleView(item?.id)
                                                }
                                                className="btn btn-primary text-white"
                                              >
                                                <i className="fa-regular fa-eye"></i>
                                              </a>
                                            </Tooltip>{" "}
                                          <Tooltip title="Delete">
                                            <button
                                              onClick={() =>
                                                showConfirmSubModule(item.id)
                                              }
                                              type="button"
                                              className="btn btn-danger"
                                            >
                                              <i
                                                className="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>{" "}
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}
                            </table>
                          </div>
                          {listSubModule?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                          <div className="fixtable">
                            {listSubModule?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationSub
                                nPages={last_page2}
                                currentPage={currentPage2}
                                setCurrentPage={setCurrentPage2}
                                link={SHOWMODULE}
                                text={text}
                                module={"0"}
                                linkSearch={SUBMODULESEARCH}
                                setData={setListSubModule}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}
