import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./textEditor.css";
import { ENDPOINT } from "../../../../api/axios";

const TextEditor = ({ handleChange, value, setFieldValue, name }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: [
          'heading', '|',
          'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
          'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|',
          'undo', 'redo', '|',
          'imageUpload', 'mediaEmbed', 'imageResize'
        ],
        image: {
          toolbar: [
            'imageTextAlternative', 'imageStyle:full', 'imageStyle:side', '|',
            'imageResize:50', 'imageResize:75', 'imageResize:original'
          ],
          resizeOptions: [
            {
              name: 'resizeImage:original',
              label: 'Original',
              value: null
            },
            {
              name: 'resizeImage:25',
              label: '25%',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              label: '50%',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              label: '75%',
              value: '75'
            },
            {
              name: 'resizeImage:100',
              label: '100%',
              value: '100'
            }
          ],
          resizeUnit: '%' // Resize unit can be '%' or 'px'
        },
        extraPlugins: [MyCustomUploadAdapterPlugin],
      }}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        setFieldValue(name, data);
      }}
    />
  );
};

// Plugin definition
function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

// Custom Upload Adapter
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then((file) => {
      return new Promise((resolve, reject) => {
        const data = new FormData();
        data.append("file", file);
        data.append("type", "image");

        // Example of custom headers
        const headers = new Headers({
          "Authorization": `Bearer ${localStorage.getItem("token")}`,  // Replace with your actual token
        });

        fetch(`${ENDPOINT}api/uploads`, {  // Replace with your actual upload URL
          method: "POST",
          headers: headers,
          body: data
        })
        .then(response => response.json())
        .then(result => {
         
          resolve({
            
            default: result.message  // URL where the image is accessible
          });
        })
        .catch(error => {
          reject(error);
        });
      });
    });
  }

  abort() {
    // Handle abort (if necessary)
  }
}

export default TextEditor;