import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../footer";
import AdminInstructorSidebar from "../adminSidebar";
// import Modal from "react-bootstrap/Modal";
import AuthContext from "../../../context/AuthProvider";
import AdminHeader from "../adminHeader";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import Pagination from "../../pages/course/addCourse/Pagination";
import { Input, Tooltip } from "antd";
import "./index.css";

import axios from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
const TESTSERIESSHOW = "/api/test-series/show-testseries-module";
const TESTSHOW = "/api/test-series/status-testseries-submodule";
const DELETEMODULE = "/api/test-series/delete-testseries-module";
const DELETESUBMODULE = "/api/test-series/delete-testseries-submodule";
const MODULESTATUS = "/api/test-series/status-testseries-module";
const SUBMODULESTATUS = "/api/test-series/status-testseries-submodule";
// const TESTSERIESSEARCH = "/api/test-series/testseries-module-search";
const SEARCHAPI = "/api/test-series/testseries-module-search";
import { Modal } from "antd";
import { notification, Space } from "antd";
import PaginationMod from "../../pages/course/addCourse/PaginationMod";
const { confirm } = Modal;

export const AdminTestSeries = () => {
  const [text, settext] = useState("");
  const [show, setShow] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setmessage] = useState("No Data");
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, setLast_page] = useState("");
  localStorage.setItem("lastVisitedRoute", window.location.pathname);

  // console.log("auth", auth);
  const navigate = useNavigate();
  const handleEdit = (id) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/edit/test-series/${ciphertexts}`);
  };
  const handleEditTest = (id) => {
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/edit/test/${ciphertexts}`);
  };
  const handleClose = () => setShow(false);
  const [data, setData] = useState(null);
  const handleCloseTest = () => setShowTest(false);
  const [listModule, setListModule] = useState(null);
  const [listSubModule, setListSubModule] = useState(null);

  const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
  }, []);
  useEffect(()=>{
    localStorage.removeItem("items")
    localStorage.removeItem("pagination")
  },[])
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fetchTestSeries = async () => {
    try {
      setLoading(true);
      const response = await axios.get(TESTSERIESSHOW, config);
      // console.log("TestSeries", response.data);
      setData(response.data.module.data);
      setLast_page(response.data.module.last_page);
      setListModule(response.data.module.data);
      setListSubModule(response.data.subModule.data);
      // setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else{
        openNotificationWithIcon2("error","Internal Server Error")
      }
    }
  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Test Series Deleted</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Status updated </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon2 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const DeleteTestSeries = async () => {
    if (id) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETEMODULE}/${id}`, config);
        // console.log("resonse", resonse);
        // toast.warning("Test Series Deleted");
        setLoading(false);
        fetchTestSeries();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
      }
    }
  };

  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this test series?",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.get(`${DELETEMODULE}/${id}`, config);
          openNotificationWithIcon("success");
          setLoading(false);
          fetchTestSeries();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else{
            openNotificationWithIcon2("error","Internal Serevr Error")
          }
        }
      },
      onCancel() {},
    });
  }
  useEffect(() => {
    fetchTestSeries();
  }, []);

  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    const response = await axios.post(
      MODULESTATUS,
      { id: id, status: result },
      config
    );
    if (response) {
      openNotificationWithIcon1("success");
    }

    const editCheck = [...listModule];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setListModule(editCheck);
  };

  const handleTestSlug = (slug, id) => {
    // let ciphertext = CryptoJS.AES.encrypt(
    //   JSON.stringify(id),
    //   "my-secret-key@admin8623009959"
    // ).toString();
    // let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/test-series/${slug}/${id}`);
  };

  const Filter = async (e) => {
    try {
      const searchValue = e.target.value;
      settext(searchValue);
      const res = await axios.post(SEARCHAPI, { name: searchValue }, config);
      if (res.data.module.data.length > 0) {
        setListModule(res.data.module.data);
        setCurrentPage(res.data.module.current_page);
        setLast_page(res.data.module.last_page);
        setmessage("No Data")
      }
      if (res.data.module.data.length== 0) {
        setListModule(res.data.module.data);
        setmessage("No Data Found")
      }
      if (searchValue.length === 0) {
        const res = await axios.get(
          `${TESTSERIESSHOW}?page=${currentPage}`,
          config
        );
        setListModule(res.data.module.data);
        setCurrentPage(1);
        setLast_page(res.data.module.last_page);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");

    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  const itemsPerPage = 10;
  return (
    <div className="main-wrapper">
      <AdminHeader activeMenu={"Test"} />
      <div className="page-content instructor-page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"Test"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget ttt">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>Test Series</h3>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="show-filter choose-search-blk">
                          <form action="#">
                            <div
                              className="row gx-2 align-items-center"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="col-md-3 col-item">
                                <div className=" search-group">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                      position: "absolute",
                                      left: "7px",
                                      color: "rgb(246, 106, 98)",
                                    }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    type="text"
                                    className="form-control "
                                    placeholder="Search Name"
                                    // allowClear
                                    onChange={Filter}
                                    onKeyDown={handleKeyPress}
                                    id="search"
                                    style={{
                                      // '@media (max-width: 768px)': {
                                      //   width: '280px',
                                      // },
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-md-2 col-item">
                                <div className=" search-group"
                                style={{display:"none"}}
                                >
                                 
                                </div>
                              </div> */}
                              <div className="col-md-6 col-lg-6 col-item ">
                                <div
                                  className="form-group select-form mb-0 d-flex flex-wrap justify-content-lg-end justify-content-sm-start"
                                  style={{ marginBottom: "-5px" }}
                                >
                                  <Button
                                    onClick={() =>
                                      navigate("/admin/add/test-series")
                                    }
                                    style={
                                      {
                                        // marginLeft: "5.2em",
                                        // width:{sm:"100%",lg:"280px"}
                                      }
                                    }
                                    className="btn btn-primary"
                                    id="custom-button"
                                  >
                                    {" "}
                                    <i className="fa-solid fa-plus"></i> Create
                                    New
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="comman-space pb-0">
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          {/* Referred Users */}
                          
                          <table
                            className="table table-nowrap mb-0"
                            style={{ tableLayout: "fixed", width: "1370px" }}
                          >
                            <thead>
                              <tr className="text-center">
                                <th>SR. NO.</th>
                                <th>NAME</th>
                                <th>MARKS</th>
                                <th>QUESTION</th>
                                <th>NEGATIVE</th>
                                <th>DURATION</th>
                                <th>PER QUESTION</th>
                                <th>STATUS</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {loading === true ? (
                             
                                <tbody   className="d-flex justify-content-center align-items-center"
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  right: 0,
                                  bottom: 0,
                                  left: 0,
                                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                                }}>
                                  <tr>
                                    <td>
                                      <div
                                        className="spinner-border text-warning"
                                        role="status"
                                      >
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                             
                            ) : (
                              listModule?.map((item, i,index) => {
                                const sNo =
                                  (currentPage - 1) * itemsPerPage + i + 1;

                                return (
                                  <tbody
                                    style={{ textTransform: "capitalize" }}
                                    key={sNo}
                                  >
                                    <tr className="text-center">
                                      {/* <td>{currentPage===1?i+1:sNo}</td> */}
                                      {currentPage === 1 || "undefined" ? (
                                        <td>{sNo}</td>
                                      ) : (
                                        <td>{sNo}</td>
                                      )}
                                      <td>
                                        <a
                                          role="button"
                                          onClick={() => {
                                            // (item.slug,item.id);
                                            handleTestSlug(item.slug, item.id);
                                            // navigate(
                                            //   `/admin/test-series/${item.slug}/${item.id}`
                                            // );
                                          }}
                                          className="refer-avatar-blk  align-items-center"
                                        >
                                          <p className="text-center">
                                            <Tooltip title={item.name}>
                                              {item.name}
                                            </Tooltip>
                                          </p>
                                        </a>
                                      </td>
                                      <td>
                                        {" "}
                                        <a>
                                          {" "}
                                          <p className="text-center">
                                            {item?.total_marks} marks
                                          </p>{" "}
                                        </a>{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        <a>
                                          {" "}
                                          <p className="text-center">
                                            {" "}
                                            {item?.total_question} Q
                                          </p>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        {" "}
                                        <a>
                                          {" "}
                                          <p className="text-center">
                                            {item?.negative_marks} marks
                                          </p>{" "}
                                        </a>{" "}
                                      </td>

                                      <td className="text-center">
                                        {" "}
                                        <a>
                                          {" "}
                                          <p className="text-center">
                                            {" "}
                                            {item?.duration} minutes
                                          </p>{" "}
                                        </a>
                                      </td>
                                      <td>
                                        {" "}
                                        <a>
                                          {" "}
                                          <p className="text-center">
                                            {" "}
                                            {Number(
                                              item?.total_marks /
                                                item?.total_question
                                            ).toFixed(2)}{" "}
                                            marks
                                          </p>{" "}
                                        </a>{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        <Switch
                                          onChange={() => {
                                            handleChange(item.id, item.status);
                                          }}
                                          checked={
                                            item.status == 1 ? true : false
                                          }
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={15}
                                          width={38}
                                          className="react-switch"
                                        />
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: " normal !important",
                                          wordWrap: "break-word",
                                          width: "300px",
                                        }}
                                      >
                                        <Tooltip title="Open Test">
                                          <Button
                                            onClick={() =>
                                              handleTestSlug(item.slug, item.id)
                                            }
                                            className="btn btn-secondary m-1"
                                          >
                                            <i
                                              className="fa fa-arrow-circle-right"
                                              style={{ fontSize: "18px" }}
                                            ></i>

                                            {/* <i className="fa-solid fa-plus"></i> */}
                                          </Button>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                          <button
                                            onClick={() => handleEdit(item.id)}
                                            type="button"
                                            className=" btn btn-info text-white m-1"
                                          >
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <Button
                                            type="button"
                                            className="btn btn-danger m-1"
                                            onClick={() => showConfirm(item.id)}
                                          >
                                            <i className="fa-regular fa-trash-can"></i>
                                          </Button>
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            )}
                          </table>
                          {listModule?.length <= 0 ? (
                            <h5 className="text-center">{message}</h5>
                          ) : (
                            ""
                          )}
                          {listModule === null ? (
                            <h5 className="text-center">{message}</h5>
                          ) : (
                            ""
                          )}
                          {/* Referred Users */}
                        </div>
                        <div className="fixtable">
                          {listModule?.length <= 0 ? (
                            ""
                          ) : (
                            <PaginationMod
                              nPages={last_page}
                              currentPage={currentPage}
                              text={text}
                              setCurrentPage={setCurrentPage}
                              link={TESTSERIESSHOW}
                              linkSearch={SEARCHAPI}
                              setData={setListModule}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
            <ToastContainer />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
