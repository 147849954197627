import React, { useContext, useEffect, useState } from "react";
import {
  Bell,
  Calendar,
  Clipboard,
  CreditCard,
  Lock,
  Power,
  RefreshCw,
  Settings,
  Trash2,
  User,
  UserPlus,
  Home,
  Server,
  Users,
} from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { ProfileBg, User11, gradientImage, userprofile } from "../../imagepath";
import axiosInstance from "../../../api/axios1";
import AuthContext from "../../../context/AuthProvider";
const COURSECATEGORY = "/api/admin/course/menu";
const UPLOADIMAGE="/api/student/editImageUserProfile";
export default function StudentSideBar({ activeMenu }) {
  // const [courses, setCourses] = useState([]);
  const { auth, courses, courseslist } = useContext(AuthContext);

  const tokenStudent = localStorage.getItem("tokenStudent");
  // const[image,setImage]=useState("")
  const studentname=localStorage.getItem("nameuser")
  const userid=localStorage.getItem("userid")

  useEffect(() => {
    const tokenStudent =localStorage.getItem('tokenStudent')
    if (!tokenStudent) {
      navigate("/login");
    }
  }, []);
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };
  // const courseslist = async () => {
  //   try {
  //     const response = await axiosInstance.get(COURSECATEGORY, config);
  //     setCourses(response?.data?.message);
  //   //  console.log(response?.data?.messge, "response in videos");
  //   } catch (error) {}
  // };
  // useEffect(() => {
  //   courseslist();
  // }, []);
// console.log(courses,"courses")
const uploadprofileimage=async()=>{

  try {
   

    const response = await axiosInstance.get(`${UPLOADIMAGE}/${userid}`, config);
    // console.log(response,"response")
    if (response) {
      // setImage(response?.data?.image)
    }
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      navigate("/login");
    }
  }
}
// useEffect(()=>{
// // uploadprofileimage();
// },[])
const image=localStorage.getItem("profile-img")

const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
     {windowWidth > 991 && (
    <div className="col-xl-3 col-md-3 col-sm-3 theiaStickySidebar">
      <div className="settings-widget dash-profile mb-3">
        <div className="settings-menu p-0">
          <div className="profile-bg">
            {/* <h5>Beginner</h5> */}
            <img src={gradientImage} alt="" />
            <div className="profile-img"
           
            >
              <Link to="/student/setting-edit-profile">
                <img  style={{objectFit:"cover",width: "150px",
    height: "150px",}} src={image?image:userprofile} alt="" />
              </Link>
            </div>
          </div>
          <div className="profile-group">
            <div className="profile-name text-center">
              <h4>
                <Link to="">{studentname}</Link>
              </h4>
              <p>Student</p>
            </div>
            <div className="go-dashboard ">
            <ul>
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={
                    activeMenu === "Dashboard" ? "nav-item active" : "nav-item"
                  }
                >
                  <Home size={20}  style={{color:"#FF875A",marginRight:"9px"}}/> {""} {""} Dashboard
                </Link>
              </li>
              <li className="nav-item">
              <div className="">
                      <h6 className="cou-title1">
                        <Link
                          data-bs-toggle="collapse"
                          to="#collapseOne"
                          aria-expanded={open}
                          onClick={() => setOpen(!open)}
                          aria-controls="example-collapse-text"
                          className={
                            ` collapsed`
                          }
                        >
                         <i className="fa-solid fa-star" style={{ fontSize: "16px" }}></i> Courses
                        </Link>
                      </h6>
                      <div id="collapseOne" className="card-collapse collapse" style={{background:"rgba(255, 222, 218, 0.25)"}}>
                        {courses?.length > 0 ? (
                           courses?.length > 0 &&
                                  courses?.map((item, i) => {
                                    return (
                                      <Link to={`/student/courses-list/${item?.id}`} className="nav-link">
                                        {item?.name}
                                      </Link>
                                    );
                                  })
                        ) : (
                          <h6 style={{ padding: "20px" }}>
                            No Test Series for this Course
                          </h6>
                        )}
                        
                      </div>
                    </div>
              </li>
              {/* {courses?.map((item)=>{
              
                  return(

                <li className="nav-item"
                key={item?.id}
                >
               
                  <Link
                    to={`/student/courses/${item?.id}`}
                    className={
                      isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    }
                  >
                       <i
            className="fas fa-book-reader"
            style={{ fontSize: "16px", color: "#ff875a" }}
          ></i> 
                  {item?.name}
                  </Link>
                  
                </li>
                  )
                })} */}
             
               
            
               
    

              <li
                className={
                  isActive("/student/videos") ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/student/videos" className="nav-link">
                  {/* <Settings size={20} /> */}
                  <i className="fa-solid fa-video" style={{ fontSize: "16px" }}></i>
                   Videos
                </Link>
              </li>
              <li
                className={
                  isActive("/student/test-series") ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/student/test-series" className="nav-link">
                  {/* <User size={20} />  */}
                  <i
                    className="fas fa-book-reader"
                    style={{ fontSize: "16px" }}
                  ></i>
                  Test Series
                </Link>
              </li>
              <li
                className={
                  isActive("/student/std-live-class") ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/student/std-live-class" className="nav-link">
                  {/* <Settings size={20} />  */}{" "}
                  <i
                    className="fa fa-television"
                    aria-hidden="true"
                    style={{ fontSize: "16px" }}
                  ></i>
                  Live Classes
                </Link>
              </li>
           
             
             
              {/* <li className="nav-item">
                <Link
                  to="/admin/teachers"
                  className={
                    activeMenu === ("Teachers" || "StudentGrid")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Users size={20} />
                  Teachers
                </Link>
              </li> */}
              {/* <li
                className={
                  isActive("/student/my-subscriptions")  ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/student/my-subscriptions" className="nav-link"
                                // onClick={windowTop(0,0)}

                >
                 <i
                    className="fa fa-credit-card"
                    style={{ fontSize: "16px" }}
                  ></i>
                  My Subscriptions
                </Link>
              </li> */}
           
              <li
               className={
                isActive("/student/chat")  ? "nav-item active" : "nav-item"
              }
              >
                  <Link
                    to="/student/chat"
                    className="nav-link"
                      
                    
                  > <i
                  className="fas fa-credit-card"
                  style={{ fontSize: "16px" }}
                ></i>
                   Chat{" "}
                  </Link>
                
                </li>
            
            </ul>
            </div>
          </div>
        </div>
      </div>
      
    </div>
     )}
    </>
  );
}
