import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../adminHeader";
import { Input, Tooltip } from "antd";

import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../../footer";
import PaginationSub from "../../pages/course/addCourse/PaginationSub";
import CryptoJS from "crypto-js";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import axios from "../../../api/axios";
import { Search } from "react-feather";
import { notification, Space, Modal } from "antd";
const { confirm } = Modal;

import AuthContext from "../../../context/AuthProvider";
const SHOWMODULE = "https://newzoomservice.chahalacademy.com/api/sub-module/get_all";
const DELETEMODULE = "https://newzoomservice.chahalacademy.com/api/sub-module/delete";
const MODULESTATUS = "https://newzoomservice.chahalacademy.com/api/sub-module/status";
const LIVECLASSESMODULEAPI = "/api/liveclasses-submodule-search";

export default function AdminLiveClassBatch() {
  // const { Search } = Input;
  const token = localStorage.getItem("token");
  // const onSearch = (value) => console.log(value);
  const config = {
    headers: { api_key:"lmsv2" }
  };
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [last_page, setLast_page] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [listModule, setListModule] = useState(null);
  const [featureTypeId, setFeatureTypeId] = useState("");
  const [text, settext] = useState("");
  const [message, setmessage] = useState("No Data");

  const handleChange = async (id, status) => {
    let bool = eval(status);
    let result = Number(!bool) + "";
    try {
      const response = await axios.put(
        `${MODULESTATUS}/${id}`,
        {  status: result },
        config
      );
      response;
      if (response) {
        openNotificationWithIcon1("success");
        fetchModule();
      }
    } catch (error) {
      console.error(error);
       if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
      openNotificationWithIcon2("error");
      }
    }
    // toast.success("Status Change");
    const editCheck = [...listModule];
    const res = editCheck.find((it) => it.id === id);
    res.status = Number(!bool) + "";
    setListModule(editCheck);
  };

  const { auth } = useContext(AuthContext);

  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const handleEditShow = (id) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/live-class-batches/edit/${ciphertexts}`);
  };

  const fetchModule = async () => {
    setLoading(true);

    try {
      const response = await axios.get(SHOWMODULE, config);
      // console.log(response)

      setListModule(response.data.message);
      // setLast_page(response.data.subModule.last_page);
      setData(response.data?.message);
      setFeatureTypeId(response?.data?.featuretypeid);
      if(response?.status==200){
        setLoading(false)
        return
        // console.log(response)
      }
      // setCurrentPage(page);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("token");
        openNotificationWithIconLogin("error", error?.response?.data?.message);
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error")
      }
    }
    setLoading(false);

  };
  const [api, contextHolder] = notification.useNotification();

  const [toast, setToast] = useState(false);
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Live Class SubModule Deleted</strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIconLogin = (type,text) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon1 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "green" }}>
          <strong>Status Updated </strong>
        </div>
      ),
      duration: 1,
      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
    });
  };
  const openNotificationWithIcon2 = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const DeleteTestSeries = async () => {
    if (id) {
      try {
        setLoading(true);
        const resonse = await axios.get(`${DELETEMODULE}/${id}`, config);
        // console.log("resonse", resonse);
        resonse;
        openNotificationWithIcon("info");
        // toast.warning("SubModule Deleted");
        setLoading(false);
        fetchModule();
        setShow(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/admin/login");
        }
        else if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          openNotificationWithIconLogin("error", error?.response?.data?.message);
          setTimeout(() => {
            navigate("/admin/login");
          }, 1000); // Delay the navigation to show the notification
        }
      }
    }
  };
  function showConfirm(id) {
    confirm({
      title: "Do you want to delete this submodule?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      okButtonProps: { style: { backgroundColor: "#ff0100" } },
      async onOk() {
        try {
          setLoading(true);
          const resonse = await axios.delete(`${DELETEMODULE}/${id}`, config);
          // console.log("resonse", resonse);
          resonse;
          openNotificationWithIcon("success");
          // toast.warning("SubModule Deleted");
          setLoading(false);
          fetchModule();
          setShow(false);
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            openNotificationWithIconSesion("error")
            localStorage.removeItem("token");
            navigate("/admin/login");
          }
          else if (error?.response?.status === 403) {
            localStorage.removeItem("token");
            openNotificationWithIconLogin("error", error?.response?.data?.message);
            setTimeout(() => {
              navigate("/admin/login");
            }, 1000); // Delay the navigation to show the notification
          }
          else{
          openNotificationWithIcon2("error");
          }
        }
      },
      onCancel() {},
    });
  }
  useEffect(() => {
    fetchModule();
  }, []);
  const handleBatch = () => {
    // var ciphertext = CryptoJS.AES.encrypt(
    //   JSON.stringify(featureTypeId),
    //   "my-secret-key@admin8623009959"
    // ).toString();
    // let ciphertexts = encodeURIComponent(ciphertext);
    navigate(`/admin/live-class-batches/add`);
  };
  const itemsPerPage = 10;

  const Filter = async (e) => {
    const searchValue = e.target.value;
    settext(searchValue);
    const res = await axios.post(
      LIVECLASSESMODULEAPI,
      { name: searchValue, module: 0 },
      config
    );
    if (res.data.subModule.data.length > 0) {
      setListModule(res.data.subModule.data);
      setCurrentPage(res.data.subModule.current_page);
      setLast_page(res.data.subModule.last_page);
      setmessage("No Data");
    }
    if (res.data.subModule.data.length == 0) {
      setListModule(res.data.subModule.data);
      setmessage("No Data Found");
    }
    if (searchValue.length === 0) {
      const res = await axios.get(`${SHOWMODULE}?page=${currentPage}`, config);
      setListModule(res.data.subModule.data);
      setCurrentPage(1);
      setLast_page(res.data.subModule.last_page);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      Filter();
    }
  };
  useEffect(() => {
    const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
    if (lastVisitedRoute) {
      localStorage.removeItem("lastVisitedRoute");
    } else {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
    }
  }, []);
  // const windowTop = (arg1, arg2) => {
  //   window.scrollTo(arg1, arg2);
  // };

  // useEffect(() => {
  //   windowTop(0, 0);
  // }, []);
  return (
    <div className="main-wrapper">
      <AdminHeader />
      <div className="page-content">
        <div
          className="container"
          style={{ minWidth: "100%", marginTop: "-15px" }}
        >
          <div className="row">
            {/* Sidebar */}
            <AdminInstructorSidebar activeMenu={"LiveClasses"} />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <h3 style={{ textTransform: "capitalize" }}>
                            Live Class SubModule
                          </h3>
                          <nav
                            aria-label="breadcrumb"
                            style={{
                              marginTop: "0.5em",
                              marginBottom: "-1.6em",
                            }}
                          >
                            <ol className="breadcrumb">
                              <li
                                className="breadcrumb-item"
                                style={{ cursor: "pointer" }}
                              >
                                <a
                                  onClick={() =>
                                    navigate("/admin/live-classes")
                                  }
                                >
                                  Live Classes
                                </a>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                                style={{ cursor: "pointer" }}
                              >
                                Live Class SubModule
                              </li>
                            </ol>
                          </nav>
                        </div>
                        <div className="comman-space pb-0">
                          <div className="instruct-search-blk">
                            <div className="show-filter choose-search-blk">
                              {/* <h4 className="text text-secondary">Batches</h4> */}
                              <form action="#">
                                {/* <p>Zoom App Details</p> */}
                                <div className="row gx-2 align-items-center">
                                  <div className="col-md-6 col-item">
                                    <div className=" search-group">
                                      <Search
                                        size={16}
                                        style={{
                                          position: "absolute",
                                          left: "7px",
                                          color: "#F66962",
                                        }}
                                      />
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Name"
                                        onChange={Filter}
                                        onKeyDown={handleKeyPress}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-item ">
                                    <div className="form-group select-form mb-0 d-flex"
                                    style={{justifyContent:"flex-end"}}
                                    >
                                      <Button
                                        onClick={() => handleBatch()}
                                        // style={{
                                        //   marginLeft: "5.2em",
                                        // }}
                                        className="btn btn-primary custom-button"
                                      >
                                        {" "}
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Create New
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="settings-referral-blk table-responsive">
                            {/* Instructor Users */}

                           
                            <table
                              className="table table-nowrap mt-2"
                              // style={{ marginTop: "15px" }}
                            >
                              <thead>
                                <tr className="text-center">
                                  <th>SR. NO.</th>
                                  <th>NAME</th>

                                  <th>STATUS</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                
                                  <tbody className="d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                  }}>
                                    <tr>
                                      <td>
                                        <div
                                          className="spinner-border text-warning"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                
                              ) : (
                                listModule?.map((item, i) => {
                                  const sNo =
                                    (currentPage - 1) * itemsPerPage + i + 1;
                                  return (
                                    <tbody key={sNo}>
                                      <tr
                                        className="text-center"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <td>{sNo}</td>
                                        <td>
                                          <a className="text-muted">
                                            <p>{item.name}</p>
                                          </a>
                                        </td>

                                        <td>
                                          <Switch
                                            onChange={() => {
                                              handleChange(
                                                item.id,
                                                item.status
                                              );
                                            }}
                                            checked={
                                              item.status === true ? true : false
                                            }
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={38}
                                            className="react-switch"
                                          />
                                        </td>
                                        <td>
                                          <Tooltip title="Edit">
                                            {" "}
                                            <button
                                              onClick={() =>
                                                handleEditShow(item.id)
                                              }
                                              type="button"
                                              className="btn btn-warning text-white"
                                            >
                                              <i className="fa-regular fa-pen-to-square"></i>
                                            </button>{" "}
                                          </Tooltip>

                                          <Tooltip title="Delete">
                                            {" "}
                                            <button
                                              onClick={() =>
                                                showConfirm(item.id)
                                              }
                                              type="button"
                                              className="btn btn-danger"
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              )}
                            </table>
                            {listModule?.length <= 0 ? (
                              <h5 className="text-center">{message}</h5>
                            ) : (
                              ""
                            )}
                            {/* Instructor List */}
                          </div>
                          {/* <div className="fixtable">
                            {listModule?.length <= 0 ? (
                              ""
                            ) : (
                              <PaginationSub
                                nPages={last_page}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                link={SHOWMODULE}
                                text={text}
                                linkSearch={LIVECLASSESMODULEAPI}
                                setData={setListModule}
                                module={0}
                              />
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}
