import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo, userprofile, ChahalLogo } from "../imagepath";
import { LogOut } from "react-feather";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip, notification } from "antd";
import axios from "../../api/axios";
import { Star, User } from "feather-icons-react/build/IconComponents";
import axiosInstance from "../../api/axios1";
const LOGOUT = "/api/student/logout";
const COURSECATEGORY = "/api/admin/course/menu";

const Header = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.remove("menu-opened");
    return () => {
      document.body.className = "";
    };
  }, []);
  const tokenStudent = localStorage.getItem("tokenStudent");
  const password_reset = localStorage.getItem("password-rest")
    ? localStorage.getItem("password-rest")
    : 0;
  // console.log( typeof password_reset ,"rest")

  const nameuser = localStorage.getItem("nameuser");
  const profile = useRef();
  const config = {
    headers: { Authorization: `Bearer ${tokenStudent}` },
  };

  const openNotificationWithIcon1 = (type) => {
    console.log("toast after click");
    notification[type]({
      message: (
        <div style={{ color: "#52c41a" }}>
          <strong>Logout Successful</strong>
        </div>
      ),

      // description:
      // (<div style={{color:"green"}}>
      // Your Batch has Sucessfully Deleted

      // </div>)
      duration: 1,
    });
  };

  const logOut = async () => {
    try {
      const responce = await axiosInstance.post(LOGOUT, {}, config);
      if (responce) {
        openNotificationWithIcon1("success");

        setTimeout(() => {
          navigate("/login");
        }, 1000);
        // toast.success("Logout Successful");
        localStorage.removeItem("tokenStudent");
      }
    } catch (error) {
      console.error(error);
      localStorage.removeItem("tokenStudent");
      navigate("/login");

      if (error?.response?.status === 401) {
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
    }
  };
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const courseslist = async () => {
    try {
      const response = await axios.get(COURSECATEGORY);
      setCourses(response?.data?.message);
      // console.log(response, "response");
    } catch (error) {}
  };
  useEffect(() => {
    courseslist();
  }, []);
  // change header background on scroll
  const [navbar, setNavbar] = useState(false);
  // Mobile Menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  const [mobileSubMenu5, setMobileSubMenu5] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const openMobileMenu = (event) => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = (event) => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu22 = (e) => {
    e.preventDefault();
    setMobileSubMenu22(!mobileSubMenu22);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };
  const openMobileSubMenu32 = (e) => {
    e.preventDefault();
    setMobileSubMenu32(!mobileSubMenu32);
  };
  const openMobileSubMenu4 = (e) => {
    e.preventDefault();
    setMobileSubMenu4(!mobileSubMenu4);
  };
  const openMobileSubMenu42 = (e) => {
    e.preventDefault();
    setMobileSubMenu42(!mobileSubMenu42);
  };
  const openMobileSubMenu43 = (e) => {
    e.preventDefault();
    setMobileSubMenu43(!mobileSubMenu43);
  };
  const openMobileSubMenu5 = (e) => {
    e.preventDefault();
    setMobileSubMenu5(!mobileSubMenu5);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeHeaderBackground);


  return (
    <div className="main-wrapper">
      <header className="header">
        <div className="header-fixed">
          <nav
            className={
              navbar
                ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
                : "navbar navbar-expand-lg header-nav scroll-sticky"
            }
          >
            <div className="container-fluid">
              <div className="navbar-header">
                <Link id="mobile_btn" to="/" onClick={openMobileMenu}>
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                </Link>
                <div className="d-block d-sm-block d-md-none"> 
                {tokenStudent && password_reset == 1 ? (
                  <li className="nav-item user-nav" style={{marginTop:"-20px"}}>
                    <Link
                      to="#"
                      className={
                        showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                      }
                      data-bs-toggle="dropdown"
                      onClick={profileClick}
                    >
                      <span className="user-img">
                        <img src={userprofile} alt="" />
                        <span className="status online"></span>
                      </span>
                    </Link>
                    <div
                      ref={profile}
                      className={
                        showProfile
                          ? "users dropdown-menu dropdown-menu-right show modalPosition"
                          : "users dropdown-menu dropdown-menu-right"
                      }
                      data-popper-placement="bottom-end"
                    >
                      <div className="user-header">
                        <div className="avatar avatar-sm">
                          <img
                            src={userprofile}
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="user-text">
                          <h6 className="single-line">{nameuser}</h6>
                          <p className="text-muted text mb-0">Student</p>
                        </div>
                      </div>
                      <Link
                        className="dropdown-item text"
                        to="/student/setting-edit-profile"
                      >
                        <User
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon"
                        />{" "}
                        Edit Profile
                      </Link>
                      <Link
                        className="dropdown-item text"
                        to="/student/setting-student-security"
                      >
                        <Star
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon"
                        />{" "}
                        Security
                      </Link>
                      <a
                        className="dropdown-item text "
                        style={{ cursor: "pointer" }}
                        onClick={logOut}
                      >
                        <LogOut
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon "
                        />{" "}
                        Logout
                      </a>
                    </div>
                  </li>
                ) : null}
                </div>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="/"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times" />
                  </Link>
                </div>
                {tokenStudent && password_reset == 1 ? (
                  <ul className="main-nav">
                    <li className="has-submenu">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="has-submenu">
                      <Link to="/dashboard">Dashboard </Link>
                    </li>
                    {courses?.map((item,index) => {
                      return (
                        index < 2 && 
                        <li className="has-submenu" key={item?.id}>
                          <Tooltip title={item?.name}>
                            <Link to={`/student/courses/${item?.id}`}>
                              {`${item?.name?.slice(0, 10)} ${
                                item?.name?.length > 10 ? "..." : ""
                              }`}
                            </Link>
                          </Tooltip>
                        </li>
                      );
                    })}
                    <li className="has-submenu d-lg-block d-md-none d-sm-none d-none "  ><a >More Courses <i className="fas fa-chevron-down"></i></a>
                <ul className={`submenu `} >
                {courses?.map((item,index)=>{
                  return(
                    index >= 2 &&  
                   
                    <li className="has-submenu">
                  <Link
                    to={`/student/courses-list/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}
                  </Link>
                
                </li>

                
                
                  
                  )
                })}
                  
                </ul></li>
                <li className="has-submenu d-lg-none d-md-block d-sm-block d-block  text-white"  ><a >More Courses <i className="fas fa-chevron-down"></i></a>
                <ul className={`submenu `} >
                {courses?.map((item,index)=>{
                  return(
                    index >= 2 &&  
                   
                    <li className="has-submenu">
                  <Link
                    to={`/student/courses-list/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}
                  </Link>
                
                </li>

                
                
                  
                  )
                })}
                  
                </ul></li>
                    <li className="has-submenu">
                      <Link to="/student/videos">Videos </Link>
                    </li>

                    <li className="has-submenu">
                      <Link to="/student/test-series">Test Series </Link>
                    </li>
                    <li className="has-submenu">
                      <Link to="/student/std-live-class">Live Classes</Link>
                    </li>
                    {/* <li className="has-submenu">
                    <Link to="/student/my-subscriptions">
                      My Subscriptions{" "}
                    </Link>
                  </li> */}
                    {/* <li className="has-submenu">
                      <Link to="/student/chat" className="nav-link">
                        Chat{" "}
                      </Link>
                    </li> */}
                  </ul>
                ) : (
                  <ul className="main-nav">
                    <li className="has-submenu">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="has-submenu">
                      <Link to="/course-grids">Courses </Link>
                    </li>
                    {/* {courses?.map((item)=>{
                  return(
                <li className="has-submenu">
                  <Link
                    to={`/student/courses/${item?.id}`}
                    // className={
                    //   isActive(`/student/courses/${item?.id}`) ? "actives" : ""
                    // }
                  >
                  {item?.name}k
                  </Link>
                  <ul>
                    <li>
                      <Link to={`/student/courses/${item?.id}`}>
                                          {item?.name}kk
                           </Link>
                    </li>
                  </ul>
                </li>
                  )
                })} */}

                    <li className="login-link">
                      <Link to="/login">Login / Signup</Link>
                    </li>
                  </ul>
                )}
              </div>
              <ul className="nav header-navbar-rht">
                {(tokenStudent && password_reset == 0) || !tokenStudent ? (
                  <li className="nav-item">
                    <Link className="nav-link header-sign" to="/login">
                      Login
                    </Link>
                  </li>
                ) : null}

                {/* <li className="nav-item">
                <Link className="nav-link header-login" to="/register">
                  Signup
                </Link>
              </li> */}
                {tokenStudent && password_reset == 1 ? (
                  <li className="nav-item user-nav">
                    <Link
                      to="#"
                      className={
                        showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                      }
                      data-bs-toggle="dropdown"
                      onClick={profileClick}
                    >
                      <span className="user-img">
                        <img src={userprofile} alt="" />
                        <span className="status online"></span>
                      </span>
                    </Link>
                    <div
                      ref={profile}
                      className={
                        showProfile
                          ? "users dropdown-menu dropdown-menu-right show modalPosition"
                          : "users dropdown-menu dropdown-menu-right"
                      }
                      data-popper-placement="bottom-end"
                    >
                      <div className="user-header">
                        <div className="avatar avatar-sm">
                          <img
                            src={userprofile}
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="user-text">
                          <h6 className="single-line">{nameuser}</h6>
                          <p className="text-muted text mb-0">Student</p>
                        </div>
                      </div>
                      <Link
                        className="dropdown-item text"
                        to="/student/setting-edit-profile"
                      >
                        <User
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon"
                        />{" "}
                        Edit Profile
                      </Link>
                      <Link
                        className="dropdown-item text"
                        to="/student/setting-student-security"
                      >
                        <Star
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon"
                        />{" "}
                        Security
                      </Link>
                      <a
                        className="dropdown-item text "
                        style={{ cursor: "pointer" }}
                        onClick={logOut}
                      >
                        <LogOut
                          size={14}
                          color={"#FF875A"}
                          className="headerIcon "
                        />{" "}
                        Logout
                      </a>
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
          </nav>
        </div>
        <ToastContainer />
      </header>
    </div>
  );
};

export default Header;
